<template lang="pug">
.laborRates
    v-card.mb-12(light, flat)
        v-card-text
            v-simple-table.laborTable.striped.hoverable(dense)
                template(v-slot:default)
                    thead
                        tr
                            th Product ID
                            th Description
                            th Tier 1
                            th Tier 2
                            th Tier 3
                    tbody
                        tr(v-for='tierConfig in tierConfigData', :key='tierConfig.id', @click.stop='editTierConfig(tierConfig)')
                            td 
                                span(v-if='!isEditingTierConfig(tierConfig)') {{ tierConfig.product_id }}
                                v-text-field(v-if='isEditingTierConfig(tierConfig)', hide-details, dense, disabled, :value='editingTierConfig.product_id')
                            td
                                span(v-if='!isEditingTierConfig(tierConfig)') {{ tierConfig.description }}
                                v-text-field(v-if='isEditingTierConfig(tierConfig)', dense, hide-details, single-line, v-model='editingTierConfig.description')
                            td
                                v-checkbox(v-if='!isEditingTierConfig(tierConfig)', disabled, color='brand', dense, v-model='tierConfig.tier_1')
                                v-checkbox(v-if='isEditingTierConfig(tierConfig)', dense, hide-details, single-line, v-model='editingTierConfig.tier_1', color='brand')
                            td
                                v-checkbox(v-if='!isEditingTierConfig(tierConfig)', disabled, color='brand', dense, v-model='tierConfig.tier_2')
                                v-checkbox(v-if='isEditingTierConfig(tierConfig)', dense, hide-details, single-line, v-model='editingTierConfig.tier_2', color='brand')
                            td
                                v-checkbox(v-if='!isEditingTierConfig(tierConfig)', disabled, color='brand', dense, v-model='tierConfig.tier_3')
                                v-checkbox(v-if='isEditingTierConfig(tierConfig)', dense, hide-details, single-line, v-model='editingTierConfig.tier_3', color='brand')
                            td
                                v-btn(icon, @click='saveTierConfig', v-if='isEditingTierConfig(tierConfig)', title='Save', color='green')
                                    v-icon save
                            td
                                v-btn(icon, @click='cancelEditTierConfig', v-if='isEditingTierConfig(tierConfig)', title='Cancel')
                                    v-icon close

                        tr
                            td.pt-2
                                v-text-field(v-model='newProductId', dense, label='Product ID', color='brand', single-line)
                            td
                                v-text-field(v-model='newDescription', dense, label='Description', color='brand', single-line)
                            td
                                v-checkbox(v-model='newTier1', dense, color='brand', single-line)
                            td
                                v-checkbox(v-model='newTier2', dense, color='brand', single-line)
                            td
                                v-checkbox(v-model='newTier3', dense, color='brand', single-line)
                            td
                                v-btn(icon, small, color='green', @click='saveNewTierConfig(newProductId)', title='Save', :disabled='addDisabled')
                                    v-icon save

                v-overlay(absolute, :value='busy')
                    .text-h6 Please Wait...
                    v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satpanels',
            message: '',
            busy: false,

            tierConfigData: [],

            newProductId: '',
            newDescription: '',
            newTier1: false,
            newTier2: false,
            newTier3: false,
            newPanelErrors: [],

            editingTierConfig: null,
            enclosureMaterialTierConfigs: ['Stainless Steel', 'Carbon Steel']
        };
    },
    methods: {
        async saveNewTierConfig () {
            // first make sure the product id isn't already added.
            let existingTierConfig = this.tierConfigData.find(p => p.product_id === this.newProductId);
            if (existingTierConfig) {
                this.message = 'Option already exists';
                return;
            }
            let newTierConfig = {
                id: dti.makeuuid(),
                product_id: this.newProductId,
                description: this.newDescription,
                tier_1: this.newTier1,
                tier_2: this.newTier2,
                tier_3: this.newTier3,
            };
            let results = await this.sendCommand({
                action: 'updatePanelTierConfig',
                parameters: newTierConfig
            });

            if (results) {
                this.message = `Tier Config added successfully`;
                this.socketEmit('getTierConfigData', null, (results) => {
                    this.tierConfigData = results;
                });
                this.newProductId = '';
                this.newDescription = '';
            } else {
                this.message = 'Error adding Tier Config. Ensure Product ID exists.';
            }
        },
        async saveTierConfig () {
            console.log(this.editingTierConfig);
            let result = await this.sendCommand({
                action: 'updatePanelTierConfig',
                parameters: this.editingTierConfig
            });
            if (result) {
                this.message = `"${this.editingTierConfig.product_id}" updated`;
                let editedTierConfig = this.tierConfigData.find(p => p.id === this.editingTierConfig.id);
                _.merge(editedTierConfig, this.editingTierConfig);
                // editedTierConfig.controller_type = this.editingTierConfig.controller_type;
                // editedTierConfig.controller_limit = this.editingTierConfig.controller_limit;
            } else {
                console.log('error updating tierConfig');
                this.message = `Error updating "${this.editingTierConfig.product_id}"`;
            }
            this.editingTierConfig = null;
            return;
        },
        editTierConfig (tierConfig) {
            if (!this.isEditingTierConfig(tierConfig)) {
                this.editingTierConfig = _.clone(tierConfig);
            }
        },
        cancelEditTierConfig (event) {
            this.editingTierConfig = null;
            event.cancelBubble = true;
        },
        isEditingTierConfig (tierConfig) {
            return this.editingTierConfig?.id === tierConfig.id;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        addDisabled () {
            return !this.newProductId;
        }
    },
    watch: {

    },
    mounted () {
        this.socketEmit('getTierConfigData', null, (results) => {
            this.tierConfigData = results;
        });
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">
.laborRates {
    .laborTable {
        td {
            position: relative;
        }
        .costInput {
            width: 100px;
        }
    }
}
</style>
