<router>
{
    name: 'Sales Tool Panels',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            v-tabs(v-model='tab', color='brand', light)
                v-tab Panels
                v-tab Panel Addons
                v-tab Panel Tier Info
            v-tabs-items(v-model='tab', light)
                v-tab-item
                    Panels
                v-tab-item
                    PanelAddons
                v-tab-item
                    PanelTierConfigs

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import Panels from '@/components/salestool/panels.vue';
import PanelAddons from '@/components/salestool/panelAddons.vue';
import PanelTierConfigs from '@/components/salestool/panelTierConfigs.vue';
export default {
    meta: {
        role: 'sat'
    },
    components: {
        Panels,
        PanelAddons,
        PanelTierConfigs
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
            tab: 0
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.satpanelsvm = this;
    }
};
</script>

<style scoped>
</style>
