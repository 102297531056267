<template lang="pug">
.laborRates
    v-card.mb-12(light, flat)
        v-card-text
            v-simple-table.laborTable.striped.hoverable(dense)
                template(v-slot:default)
                    thead
                        tr
                            th Product ID
                            th Jonas Description
                            th Custom Description
                            th
                    tbody
                        tr(v-for='addon in addonData', :key='addon.id', @click.stop='editAddon(addon)')
                            td 
                                span(v-if='!isEditingAddon(addon)') {{ addon.product_id }}
                                v-text-field(v-if='isEditingAddon(addon)', hide-details, dense, disabled, :value='editingAddon.product_id')
                            td
                            
                            td
                                span(v-if='!isEditingAddon(addon)') {{ addon.description }}
                                v-text-field(v-if='isEditingAddon(addon)', dense, hide-details, single-line, v-model='editingAddon.description')
                            td
                                v-btn(icon, @click='saveAddon', v-if='isEditingAddon(addon)', title='Save', color='green')
                                    v-icon save
                            td
                                v-btn(icon, @click='cancelEditAddon', v-if='isEditingAddon(addon)', title='Cancel')
                                    v-icon close

                        tr
                            td.pt-2
                                v-text-field(v-model='newProductId', dense, label='Product ID', color='brand', single-line)
                            td
                            td
                                v-text-field(v-model='newDescription', dense, label='Description', color='brand', single-line)
                            td
                                v-btn(icon, small, color='green', @click='saveNewAddon(newProductId)', title='Save', :disabled='addDisabled')
                                    v-icon save

                v-overlay(absolute, :value='busy')
                    .text-h6 Please Wait...
                    v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satpanels',
            message: '',
            busy: false,

            addonData: [],

            newProductId: '',
            newDescription: '',
            newPanelErrors: [],

            editingAddon: null,
            enclosureMaterialOptions: ['Stainless Steel', 'Carbon Steel']
        };
    },
    methods: {
        async saveNewAddon () {
            // first make sure the product id isn't already added.
            let existingAddon = this.addonData.find(p => p.product_id === this.newProductId);
            if (existingAddon) {
                this.message = 'Panel already exists';
                return;
            }
            let newAddon = {
                id: dti.makeuuid(),
                product_id: this.newProductId,
                description: this.newDescription
            };
            let results = await this.sendCommand({
                action: 'updatePanelAddon',
                parameters: newAddon
            });

            if (results) {
                this.message = `Addon added successfully`;
                this.socketEmit('getPanelAddonsData', null, (results) => {
                    this.addonData = results;
                });
                this.newProductId = '';
                this.newDescription = '';
            } else {
                this.message = 'Error adding addon. Ensure Product ID exists.';
            }
        },
        async saveAddon () {
            console.log(this.editingAddon);
            let result = await this.sendCommand({
                action: 'updatePanelAddon',
                parameters: this.editingAddon
            });
            if (result) {
                this.message = `"${this.editingAddon.product_id}" updated`;
                let editedAddon = this.addonData.find(p => p.id === this.editingAddon.id);
                _.merge(editedAddon, this.editingAddon);
                // editedAddon.controller_type = this.editingAddon.controller_type;
                // editedAddon.controller_limit = this.editingAddon.controller_limit;
            } else {
                console.log('error updating addon');
                this.message = `Error updating "${this.editingAddon.product_id}"`;
            }
            this.editingAddon = null;
            return;
        },
        editAddon (addon) {
            if (!this.isEditingAddon(addon)) {
                this.editingAddon = _.clone(addon);
            }
        },
        cancelEditAddon (event) {
            this.editingAddon = null;
            event.cancelBubble = true;
        },
        isEditingAddon (addon) {
            return this.editingAddon?.id === addon.id;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        addDisabled () {
            return !this.newProductId;
        }
    },
    watch: {

    },
    mounted () {
        this.socketEmit('getPanelAddonsData', null, (results) => {
            this.addonData = results;
        });
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">
.laborRates {
    .laborTable {
        td {
            position: relative;
        }
        .costInput {
            width: 100px;
        }
    }
}
</style>
