import { render, staticRenderFns } from "./panelTierConfigs.vue?vue&type=template&id=a65a182e&scoped=true&lang=pug"
import script from "./panelTierConfigs.vue?vue&type=script&lang=js"
export * from "./panelTierConfigs.vue?vue&type=script&lang=js"
import style1 from "./panelTierConfigs.vue?vue&type=style&index=1&id=a65a182e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a65a182e",
  null
  
)

export default component.exports