import { render, staticRenderFns } from "./panels.vue?vue&type=template&id=4de48cf3&scoped=true&lang=pug"
import script from "./panels.vue?vue&type=script&lang=js"
export * from "./panels.vue?vue&type=script&lang=js"
import style1 from "./panels.vue?vue&type=style&index=1&id=4de48cf3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de48cf3",
  null
  
)

export default component.exports