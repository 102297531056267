<template lang="pug">
.laborRates
    v-card.mb-12(light, flat)
        v-card-text
            v-simple-table.laborTable.striped.hoverable(dense)
                template(v-slot:default)
                    thead
                        tr
                            th Product ID
                            th Jonas Description
                            th Controller Description
                            th Controller Capacity
                            th Tier
                            th Includes Enclosure
                            th Enclosure Material
                    tbody
                        tr(v-for='panel in panelData', :key='panel.id', @click.stop='editPanel(panel)')
                            td
                                span(v-if='!isEditingPanel(panel)') {{ panel.product_id }}
                                v-text-field(v-if='isEditingPanel(panel)', hide-details, dense, disabled, :value='editingPanel.product_id')
                            td
                                span(v-if='!isEditingPanel(panel)') {{ panel.description }}
                                v-text-field(v-if='isEditingPanel(panel)', dense, hide-details, disabled, single-line, :value='editingPanel.description')
                            td
                                span(v-if='!isEditingPanel(panel)') {{ getController(panel.controller_type).description}}
                                v-select(v-if='isEditingPanel(panel)', v-model='editingPanel.controller_type', dense, hide-details, :items='controllerData', color='brand', item-text='description', item-value='product_id')
                            td
                                span(v-if='!isEditingPanel(panel)') {{ panel.controller_limit }}
                                v-text-field(v-if='isEditingPanel(panel)', type='number', dense, hide-details, v-model.number='editingPanel.controller_limit')
                            td
                                span(v-if='!isEditingPanel(panel)') {{ panel.tier }}
                                v-text-field(v-if='isEditingPanel(panel)', type='number', dense, hide-details, v-model.number='editingPanel.tier')
                            td
                                v-checkbox(v-if='!isEditingPanel(panel)', disabled, color='brand', dense, v-model='panel.includes_enclosure')
                                v-checkbox(v-if='isEditingPanel(panel)', dense, hide-details, single-line, v-model='editingPanel.includes_enclosure', color='brand')
                            td
                                span(v-if='!isEditingPanel(panel)', dense) {{ panel.enclosure_material }}
                                v-select(v-if='isEditingPanel(panel)', dense, hide-details, single-line, v-model='editingPanel.enclosure_material', :items='enclosureMaterialOptions', clearable)
                            td
                                v-btn(icon, @click='savePanel', v-if='isEditingPanel(panel)', :disabled='saveEditDisabled', title='Save', color='green')
                                    v-icon save
                            td
                                v-btn(icon, @click='cancelEditPanel', v-if='isEditingPanel(panel)', title='Cancel')
                                    v-icon close
                            td
                                v-btn(icon, small, color='red', v-if='isEditingPanel(panel)', @click='deletePanel',:disabled='saveEditDisabled', title='Delete')
                                    v-icon delete
                        tr
                            td.pt-2
                                v-text-field(v-model='newProductId', dense, label='Product ID', color='brand', single-line)
                            td
                            td.pt-2
                                v-select(v-model='selectedController', dense, :items='controllerData', label='Controller', color='brand', return-object, item-text='description')
                            td.pt-2
                                v-text-field(v-model.number='controllerQty', type='number', dense, label='Controller Capacity', color='brand', single-line)
                            td
                                v-btn(icon, small, color='green', @click='saveNewPanel(newProductId)', title='Save', :disabled='addDisabled')
                                    v-icon save

                v-overlay(absolute, :value='busy')
                    .text-h6 Please Wait...
                    v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}

</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satpanels',
            message: '',
            busy: false,

            panelData: [],
            controllerData: [],

            newProductId: '',
            newPanelErrors: [],
            selectedController: null,
            controllerQty: null,
            tier: null,
            includesEnclosure: false,
            enclosureMaterial: null,

            editingPanel: null,
            enclosureMaterialOptions: ['Stainless Steel', 'Carbon Steel']
        };
    },
    methods: {
        getController (productId) {
            return this.controllerData.find(controller => controller.product_id === productId);
        },
        async saveNewPanel () {
            // first make sure the product id isn't already added.
            let existingPanel = this.panelData.find(p => p.product_id === this.newProductId);
            if (existingPanel) {
                this.message = 'Panel already exists';
                return;
            }
            let newPanel = {
                id: dti.makeuuid(),
                product_id: this.newProductId,
                controller_type: this.selectedController.product_id,
                controller_limit: this.controllerQty,
                tier: this.tier,
                includes_enclosure: this.includesEnclosure,
                enclosure_material: this.enclosureMaterial,
            };
            let results = await this.sendCommand({
                action: 'addPanel',
                parameters: newPanel
            });

            if (results) {
                this.message = `Panel added successfully`;
                this.socketEmit('getPanelData', null, (results) => {
                    this.panelData = results.panels;
                });

            } else {
                this.message = 'Error adding panel. Ensure Product ID exists.';
            }
        },
        async savePanel () {
            console.log(this.editingPanel);
            let result = await this.sendCommand({
                action: 'updatePanel',
                parameters: this.editingPanel
            });
            if (result) {
                this.message = `"${this.editingPanel.product_id}" updated`;
                let editedPanel = this.panelData.find(p => p.id === this.editingPanel.id);
                _.merge(editedPanel, this.editingPanel);
                // editedPanel.controller_type = this.editingPanel.controller_type;
                // editedPanel.controller_limit = this.editingPanel.controller_limit;
            } else {
                console.log('error updating panel');
                this.message = `Error updating "${this.editingPanel.product_id}"`;
            }
            this.editingPanel = null;
            return;
        },
        async deletePanel () {
            console.log(this.editingPanel);
            let result = await this.sendCommand({
                action: 'deletePanel',
                parameters: this.editingPanel
            });
            if (result) {
                this.message = `"${this.editingPanel.product_id}" removed`;
                let editedPanel = this.panelData.find(p => p.id === this.editingPanel.id);
                _.merge(editedPanel, this.editingPanel);
                // editedPanel.controller_type = this.editingPanel.controller_type;
                // editedPanel.controller_limit = this.editingPanel.controller_limit;
                this.getData();
            } else {
                console.log('error removing panel');
                this.message = `Error removing "${this.editingPanel.product_id}"`;
            }
            this.editingPanel = null;
            return;
        },
        editPanel (panel) {
            if (!this.isEditingPanel(panel)) {
                this.editingPanel = _.clone(panel);
            }
        },
        cancelEditPanel (event) {
            this.editingPanel = null;
            event.cancelBubble = true;
        },
        isEditingPanel (panel) {
            return this.editingPanel?.id === panel.id;
        },
        getData () {
            this.socketEmit('getPanelData', null, (results) => {
                this.controllerData = results.controllers;
                this.panelData = results.panels.filter(panel => panel.active);
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        addDisabled () {
            return this.newProductId === '' || this.selectedController === null || this.controllerQty === 0;
        },
        saveEditDisabled () {
            return this.editingPanel.controller_limit <= 0;
        }
    },
    watch: {

    },
    mounted () {
        window.satpanelsvm = this;
        this.getData();
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">
.laborRates {
    .laborTable {
        td {
            position: relative;
        }
        .costInput {
            width: 100px;
        }
    }
}
</style>
